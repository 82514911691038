<template>
    <b-card no-body class="p-1" ref="userAccounts">
        <b-overlay
                :show="state.loading"
                rounded="sm"
        >
            <div class="demo-spacing-0 m-1 d-flex justify-content-between align-items-center"
                 dir="rtl"
            >

            </div>

            <div class="relative-over-x">
                <b-row class="mt-1">
                    <b-col>

                        <b-form @submit.prevent="updateConfig">
                                <b-form-group label="نام صاحب حساب">
                                    <b-form-input v-model="formData.account_owner" />
                                </b-form-group>

                                <b-form-group label="شماره حساب">
                                    <b-form-input v-model="formData.account_number" />
                                </b-form-group>

                                <b-form-group label="شماره کارت">
                                    <b-form-input v-model="formData.card_number"
                                    />
                                </b-form-group>

                                <b-form-group label="شماره شبا">
                                    <b-form-input v-model="formData.sheba"
                                    />
                                </b-form-group>



                            <b-button type="submit" variant="primary">ارسال</b-button>
                        </b-form>


                    </b-col>
                </b-row>
            </div>

        </b-overlay>
    </b-card>
</template>

<script>
import {
    BButton,
    BCard,
    BCol,
    BOverlay,
    BRow,
    BForm,
    BFormGroup,
    BFormInput,
    // BInputGroup
} from "bootstrap-vue";

export default {
    name: "BankAccount",
    components: {
        BCard,
        BOverlay,
        BButton,
        BCol,
        BRow,
        BForm,
        BFormGroup,
        BFormInput,
        // BInputGroup,
    },
    data() {
        return {
            formData : {
                sheba: 0,
                card_number: 0,
                account_number:0,
                account_owner:'',
            }
        }
    },
    methods: {
        async updateConfig() {
            await this.$axios.post('/admin-account', this.formData)

            this.$swal({
                icon: 'success',
                title: 'اطلاعات با موفقیت ویرایش است.',
                confirmButtonText: 'تایید',
                // text: 'Your file has been deleted.',
                customClass: {
                    confirmButton: 'btn btn-success',
                },
            })
        },
        async getData() {
            const res = await this.$axios.get('/admin-account')
            const data = res.data.data

            if (data.length > 0) {
                for (let item of data) {
                    this.formData[item.key.toLowerCase()] = item
                }
            }
        }
    },
    async mounted() {
        await this.getData();
    }
}
</script>

<style scoped>

</style>
